.auth {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    margin: 20px 15px;

    &__title {
        margin: 0;
    }

    &__subtitle {
        margin-top: 8px;
        color: grey;
    }

    &__elem {
        margin: 10px 0!important;
        display: flex; 

        .MuiFilledInput-root {  
            background-color: #F2F7FA;
        }

        &.btn-group {
            justify-content: space-around;

            button {
                margin: 0;
            }
        }
    }
    
    &__end {
        display: flex;
        margin: 10px 0 0;
        justify-content: flex-end;
        font-size: 12px;

        a {
            text-decoration: none;
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9999;

    &-window {
        padding: 35px;
        background: rgba(255, 255, 255, 1);
        font-size: 16px;
        text-align: center;
        border-radius: 10px;

        button {
            margin: 20px auto 0;
        }
    }
}