@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

a.underline {
    text-decoration: underline;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.col {
    width: 100%;
    margin: 0 15px;
}

.flex-grow-1 {
    width: 41%;
}

.flex-grow-2 {
    width: 59%;
}

.btn-group {
    display: flex;

    button {
        margin-left: 40px;
    }
}

.jcsb {
    display: flex;
    justify-content: space-between;
}

.jcfe {
    display: flex;
    justify-content: flex-end;
}

.top {
    margin-bottom: 60px;
}

button[disabled] {
    opacity: .5;

    &:hover {
        opacity: .5;
        cursor: default;
    }
}

.fageBlock {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 120%;
    background-color: white;
    z-index: 100000;
    box-shadow: 0 0 50px 80px white;
    animation: 1.5s loading-widget;

    @keyframes loading-widget { from { left: 0% } to { left: 120% }  }
}
