.diagram {
    width: 100%;
    min-width: 700px;

    &-scroll {
        width: 100%;;
        overflow: hidden;
        overflow-x: auto;
    }

    &-main {
        display: flex;
    }

    &-left {
        width: 55px;

        &__title {
            position: relative;

            &-second {
                position: absolute;
                right: 17px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #AEAEAE;
                transform: translateY(-50%);
            }
        }
    }


    &-svg {
        width: calc(100% - 55px);
        background: #F2F7FA;
        border-radius: 4px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__line {
            position: absolute;
        }

        &__point {
            position: absolute;

            &:hover .diagram-svg__point-popup {
                display: block;
            }

            &-elem {
                width: 8px;
                height: 8px;
                box-shadow: 0px 2px 10px rgba(50, 47, 109, 0.25);
                border-radius: 30px;
                border: 2px solid #FFFFFF;
            }

            img {
                position: absolute;
                left: 150%;
                bottom: 50%;
            }
        }
    }

    &-bottom {
        padding-left: 55px;
        position: relative;

        &__title {
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            &-first {
                position: absolute;
                right: 0;
                font-size: 10px;
                line-height: 15px;
                text-align: center;
                color: #B8B4B4;
            }

            &-second {
                position: absolute;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #AEAEAE;
                transform: translateX(-50%);
            }
        }

        &-legend {
            display: flex;
            justify-content: space-around;
            margin: 40px 0 20px;

            &__elem {
                display: flex;
                align-items: center;
                margin: 0 10px;

                &-point {
                    height: 3px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                }

                &-subpoint {
                    height: 6px;
                    width: 6px;
                    border: 2px solid #FFFFFF;
                    border-radius: 10px;
                    box-shadow: 0px 0px 4px rgba(50, 47, 109, 0.25);
                }

                &-title {
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: #989899;
                    white-space: nowrap;
                }
            }
        }
    }
}